@import './variable';
@import './typography';

.start-container {
    .logo {
        img {
            position: absolute;
            width: 80%;
            max-width: 360px;
            margin: auto;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
        }
    }
    .next-btn {
        position: absolute;
        bottom: 9%;
    }
}

@import './variable';
@import './typography';

.qr-bg-camera-container {
    position: relative;
    height: calc(100vh - 94px);
    margin-left: -12px;
    margin-right: -12px;
    width: initial;

    .text {
        position: relative;
        width: 100%;
        margin-top: 4rem;
        margin-left: auto;
        color: #ffffff;
        z-index: 9;
        margin-right: auto;
    }

    /* .qr-transparent-bg {
    position: absolute;
    background: url('../assets/imgs/qr-transparent-bg.svg') no-repeat;
    width: 12.5rem;
    height: 12.5rem;
    top: 40%;
    bottom: 0;
    z-index: 9;
    left: 50%;
    transform: translate(-50%, -50%);
  } */
    .qr-bg {
        position: absolute;
        background: url('../assets/imgs/qrscanner-outline.svg') no-repeat;
        width: 12.5rem;
        height: 12.5rem;
        top: 40%;
        bottom: 0;
        z-index: 9;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .qr {
        position: absolute;
        width: 100%;
        top: 50%;
        left: 50%;
        height: calc(100vh - 94px);
        transform: translate(-50%, -50%);

        .custom-qr-scan {
            position: absolute;
            height: calc(100vh - 94px);
            width: 100%;
            top: 0;
            left: 0;

            > div {
                height: calc(100vh - 95px) !important;
                padding: 0 !important;

                video {
                    top: 0px;
                    left: 0px;
                    width: 100%;
                    height: calc(100vh - 95px);
                    display: block;
                    overflow: hidden;
                    position: absolute;
                    object-fit: cover;
                }
            }
        }
    }
}

.bottom-container {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 30% !important;
    background: $bg-200-rgba06;

    .text {
        align-self: unset;
        // flex-grow: 1;
        // height: 40%;

        > div {
            padding-top: 5%;
        }
    }

    .cancel-btn-container {
        // flex-grow: 1;
        // height: 60%;
        text-align: center;

        button {
            margin-top: 3%;

            &.btn-disabled {
                pointer-events: auto;
            }
        }
    }
}

.qr-bg {
    position: absolute;
    background: url('../assets/imgs/qrscanner-outline.svg') no-repeat;
    width: 12.5rem;
    height: 12.5rem;
    top: 40%;
    bottom: 0;
    z-index: 9;
    left: 50%;
    transform: translate(-50%, -50%);
}

@import './variable';
@import './typography';

a,
.link {
    //14px/22px
    font: normal $weight-600 0.875rem/1.375rem $font-1;
    letter-spacing: 0.01em;
}

.white {
    color: $white !important;
}
.white-bg {
    background: $white !important;
}

.light-grey {
    color: $light-grey !important;
}

.container-fluid {
    // background: $bg-100;
    height: 100%;
}

.container {
    height: 100%;
    background: $white;
}

.loading {
    position: absolute;
    top: 50%;
    left: 0%;
    text-align: center !important;
    width: 100%;
    color: $white;
}

.not-registered {
    color: $white;
}

.text-color-default {
    color: $dark-grey;
}

.text-color-active {
    color: $white;
}

.test-switch {
    .form-check-input {
        width: 3.188rem;
        height: 1.938rem;
        background-color: $bg-400;
        border: none;
        background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e');

        &:checked {
            background-color: $turquoise;
        }

        &:focus {
            box-shadow: none;
            background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e');
        }
    }
}

.app {
    position: absolute;
    max-width: 61.25rem;
    min-height: 100vh;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto;

    &.default-layout {
        overflow: scroll;
        scrollbar-width: none; // mozilla
    }

    &.default-layout::-webkit-scrollbar {
        // Chrome, Safari, Opera
        height: 0;
        width: 0;
    }

    > .wrapper {
        // background: $bg-100;
        width: 100%;
        height: 100%;

        .custom-body {
            // height: calc(100% - 8.163rem);
            // height: 100%;
            height: calc(100% - 5.875rem);
        }

        .custom-body-full {
            height: 100%;
        }
    }
}

.in-call-app {
    position: absolute;
    // max-width: 61.25rem;
    min-height: 100vh;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto;

    &.default-layout {
        overflow: scroll;
        scrollbar-width: none; // mozilla
    }

    &.default-layout::-webkit-scrollbar {
        // Chrome, Safari, Opera
        height: 0;
        width: 0;
    }

    > .wrapper {
        // background: $bg-100;
        width: 100%;
        height: 100%;

        .custom-body {
            // height: calc(100% - 8.163rem);
            // height: 100%;
            height: calc(100% - 5.875rem);
        }

        .custom-body-full {
            height: 100%;
        }
    }
}

.font-20px {
    font-size: 1.25rem;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.spinner-bg {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;

    .show {
        display: block;
    }

    .hide {
        display: none;
    }

    .spinner {
        background: url('../assets/imgs/loader.svg') no-repeat;
        height: 3.75rem;
        width: 3.75rem;
        margin: auto;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        animation: spin 1.5s linear infinite;
        z-index: 9;
    }

    .spinner-header {
        margin: auto;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        height: 1rem;
        width: 100%;
        text-align: center;
        padding-top: 6rem;
        color: $white;
    }

    .spinner-content {
        margin: auto;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        height: 1rem;
        width: 100%;
        text-align: center;
        padding-top: 10rem;
        color: $white;
    }
}

::-webkit-scrollbar {
    width: 0.688rem;
    height: 0.688rem;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: $light-grey;
    border-radius: 9999px;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
}

::-webkit-scrollbar-corner {
    border-radius: 3px;
    background-color: transparent;
}

.bg-300 {
    color: $bg-300;
}

.turquiose {
    color: $turquoise;
}

/* prevent pull-to-refresh for Safari 16+ */
@media screen and (pointer: coarse) {
    @supports (-webkit-backdrop-filter: blur(1px)) and (overscroll-behavior-y: none) {
        html {
            min-height: 100.3%;
            overscroll-behavior-y: none;
            overscroll-behavior-x: none;
        }
    }
}
/* prevent pull-to-refresh for Safari 9~15 */
@media screen and (pointer: coarse) {
    @supports (-webkit-backdrop-filter: blur(1px)) and (not (overscroll-behavior-y: none)) {
        html {
            height: 100%;
            overflow: hidden;
        }
        body {
            margin: 0px;
            max-height: 100%; /* or `height: calc(100% - 16px);` if body has default margin */
            overflow: auto;
            -webkit-overflow-scrolling: touch;
        }
        /* in this case to disable pinch-zoom, set `touch-action: pan-x pan-y;` on `body` instead of `html` */
    }
}

.in-call-icon {
    color: #fff;
}

.no-network {
    position: absolute;
    height: 100vh;
    width: 100vw;
    backdrop-filter: blur(5px);
    z-index: 10;
    .content-section {
        position: absolute;
        background: $dark-grey;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: fit-content;
        width: max-content;
        padding: 3% 4%;
        border-radius: 10px;
        color: $white;
        font-family: $font-1;
        user-select: none;
        text-align: center;
    }
}

.cursor-pointer {
    cursor: pointer;
}

.button-loader {
    height: 30px;
    width: 30px;
}

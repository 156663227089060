@font-face {
    font-family: 'icomoon';
    src: url('./../assets/icons/icomoon.eot?xcemme');
    src:
        url('./../assets/icons/icomoon.eot?xcemme#iefix') format('embedded-opentype'),
        url('./../assets/icons/icomoon.ttf?xcemme') format('truetype'),
        url('./../assets/icons/icomoon.woff?xcemme') format('woff'),
        url('./../assets/icons/icomoon.svg?xcemme#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon', sans-serif !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-add:before {
    content: '\e900';
    color: #878ea0;
}

.icon-add-time:before {
    content: '\e901';
    color: #878ea0;
}

.icon-add-user:before {
    content: '\e902';
    color: #878ea0;
}

.icon-back:before {
    content: '\e903';
    color: #878ea0;
}

.icon-change-portal:before {
    content: '\e904';
    color: #878ea0;
}

.icon-checkbox:before {
    content: '\e905';
    color: #878ea0;
}

.icon-checked:before {
    content: '\e906';
    color: #878ea0;
}

.icon-clock:before {
    content: '\e907';
    color: #ffffff;
}

.icon-close:before {
    content: '\e908';
    color: #878ea0;
}

.icon-connected:before {
    content: '\e909';
    color: #4caf50;
}

.icon-container:before {
    content: '\e90a';
    color: #878ea0;
}

.icon-down-arrow:before {
    content: '\e90b';
    color: #878ea0;
}

.icon-end-call:before {
    content: '\e90c';
    color: #878ea0;
}

.icon-error:before {
    content: '\e90d';
    color: #878ea0;
}

.icon-info:before {
    content: '\e90e';
    color: #878ea0;
}

.icon-loader:before {
    content: '\e90f';
    color: #878ea0;
}

.icon-logout:before {
    content: '\e910';
    color: #878ea0;
}

.icon-mute:before {
    content: '\e911';
    color: #878ea0;
}

.icon-not-connected:before {
    content: '\e912';
    color: #f44336;
}

.icon-pin:before {
    content: '\e913';
    color: #878ea0;
}

.icon-record-off:before {
    content: '\e914';
    color: #878ea0;
}

.icon-record-on:before {
    content: '\e915';
    color: #878ea0;
}

.icon-right-arrow:before {
    content: '\e916';
    color: #878ea0;
}

.icon-search:before {
    content: '\e917';
    color: #878ea0;
}

.icon-settings:before {
    content: '\e918';
    color: #878ea0;
}

.icon-unmute:before {
    content: '\e919';
    color: #878ea0;
}

.icon-up-arrow:before {
    content: '\e91a';
    color: #878ea0;
}

.icon-user:before {
    content: '\e91b';
    color: #878ea0;
}

.icon-users:before {
    content: '\e91c';
    color: #878ea0;
}

.icon-video-off:before {
    content: '\e91d';
    color: #878ea0;
}

.icon-video-on:before {
    content: '\e91e';
    color: #878ea0;
}

.icon-volume-high:before {
    content: '\e91f';
    color: #878ea0;
}

.icon-volume-low:before {
    content: '\e920';
    color: #878ea0;
}

.icon-volume-off:before {
    content: '\e921';
    color: #878ea0;
}
